import ApiService from './api';

export default class TagService extends ApiService {
  async getTags(params = {}) {
    return this.get('/tag/', params);
  }

  async createTag(body) {
    return this.post('/tag/', body);
  }

  async deleteTag(id) {
    return this.delete('/tag/' + id);
  }
}
